import { createApp } from "vue";
import App from "./App.vue";
import '@/assets/tailwind.css'; // Correct path to your Tailwind CSS file
import "@/assets/style.css";
import router from "./router";
// main.js

import store from "./store";

createApp(App).use(store).use(router).mount("#app");
