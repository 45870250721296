<template>
  <div class="bg-gray-800 text-white py-6  px-6">
    <div class="flex  justify-between mx-auto text-center"><!-- Social Media Links -->
      <p class="text-sm">
        &copy; 2024 Mahjouba Bouchouf
      </p>

      <div class="flex justify-center mb-4 space-x-4">
        <a
          v-for="social in socials"
          :key="social.name"
          :href="social.link"
          class="text-gray-400 hover:text-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img :src="social.icon" :alt="social.name" class="w-4 h-4 inline" />
        </a>
      </div>

    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import store from "@/store";
const data = computed(() => store.getters.getData);
// Get the current year
const currentYear = ref(new Date().getFullYear());

// Social media links and icons from data
const socials = data.value.socials;
</script>

<style scoped>
/* Additional styles for social media icons */
a {
  transition: transform 0.2s ease;
}

a:hover {
  transform: scale(1.1);
}
</style>
